const lodash = require("lodash");

export default {
	qualifiedOpportunities: () => {
		return {
			title: "Sales qualified opportunities",
			amount: 0,
			link: "pipeline/details",
			option: {
				stringDate: true,
				chartType: "line",
				xAxis: {
					data: [],
				},
				yAxis: {
					name: "OPPORTUNITY COUNTS",
					nameGap: 40,
				},
				series: [],
			},
		};
	},

	qualifiedOpportunitySource: () => {
		return {
			title: "Sales qualified opportunity source",
			amount: 0,
			link: "/pipeline/details",
			option: {
				chartType: "bar",
				colorBy: true,
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "OPPORTUNITY SOURCE COUNTS",
					nameGap: 40,
				},
				series: [],
			},
		};
	},

	opportunityConversionRate: () => {
		return {
			title: "Opportunity to win conversion rate",
			amount: 0,
			totalAmount: 0,
			amountSuffix: "%",
			link: "/pipeline/details",
			option: {
				chartType: "bar",
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "OPPORTUNITY COUNT",
					nameGap: 40,
				},
				series: [],
			},
		};
	},

	pipelineByStage: () => {
		const commonDetails = {
			title: "Pipeline by stage",
			amount: 0,
			link: "/pipeline/details",
			displayToggle: true,
			hidePercentage: true,
			option: {
				chartType: "bar",
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {},
				series: [],
			},
		};

		const countDetails = lodash.cloneDeep(commonDetails);
		const valueDetails = lodash.cloneDeep(commonDetails);
		const percentageDetails = lodash.cloneDeep(commonDetails);

		countDetails.option.yAxis = {
			name: "OPPORTUNITY COUNTS",
			nameGap: 40,
			axisLabel: {
				formatter: "{value}",
			},
		};

		countDetails.option.series = [];

		valueDetails.amount = 0;
		valueDetails.amountPrefix = "$";
		valueDetails.option.yAxis = {
			name: "OPPORTUNITY VALUE",
			axisLabel: {
				formatter: "${value}",
			},
			nameGap: 90,
		};

		valueDetails.option.series = [];

		percentageDetails.amount = 0;
		percentageDetails.amountSuffix = "%";
		percentageDetails.option.yAxis = {
			name: "OPPORTUNITY PERCENTAGE",
			nameGap: 35,
			axisLabel: {
				formatter: "{value}",
			},
		};

		percentageDetails.option.series = [];

		return {
			countDetails,
			valueDetails,
			percentageDetails,
		};
	},

	pipelineByOwner: () => {
		return {
			title: "Pipeline by owner",
			amount: 0,
			amountPrefix: "$",
			link: "/pipeline/details",
			option: {
				chartType: "bar",
				chartColumn: "stack",
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "TOTAL AMOUNT",
					axisLabel: {
						formatter: "${value}",
					},
					nameGap: 90,
				},
				series: [],
			},
		};
	},

	pipelineByCreatedDate: () => {
		return {
			title: "Pipeline by created date",
			amount: 0,
			amountPrefix: "$",
			link: "/pipeline/details",
			option: {
				chartType: "bar",
				chartColumn: "stack",
				stringDate: true,
				xAxis: {
					type: "category",
					boundaryGap: true,
					sortData: true,
					data: [],
				},
				yAxis: {
					name: "TOTAL AMOUNT",
					axisLabel: {
						formatter: "${value}",
					},
					nameGap: 88,
				},
				series: [],
			},
		};
	},

	lostOpportunitiesByReason: () => {
		return {
			title: "Lost opportunities by reason",
			amount: 0,
			amountPrefix: "$",
			link: "/pipeline/details",
			option: {
				chartType: "bar",
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "SUM OF SALES VALUE",
					axisLabel: {
						formatter: "${value}",
					},
					nameGap: 90,
				},
				series: [],
			},
		};
	},

	averageSalesCycle: () => {
		return {
			title: "Average sales cycle length",
			amount: 0,
			link: "/pipeline/details",
			option: {
				chartType: "bar",
				colorBy: true,
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "AVERAGE DAYS IN STAGE",
					nameGap: 45,
				},
				series: [],
			},
		};
	},

	salesRepActivity: () => {
		return {
			title: "Sales rep activity",
			amount: 0,
			link: "/pipeline/details",
			option: {
				chartType: "bar",
				chartColumn: "stack",
				xAxis: {
					type: "category",
					boundaryGap: true,
					data: [],
				},
				yAxis: {
					name: "ACTIVITY COUNTS",
					nameGap: 40,
				},
				series: [],
			},
		};
	},
};
